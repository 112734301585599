import { filterDataByLocale, resolveLocale } from '@sprinklr/shared-lib/utils';

export const getLocalizedOurStoryPagePeople = (
  contentfulPersonList,
  i18LanguageCode,
) => {
  const sortedPeopleWithResolvedLocale = contentfulPersonList
    .map(person => {
      const locale = resolveLocale(person.nodeLocale);
      person.nodeLocale = locale ? locale : 'en';
      return person;
    })
    .sort((person1, person2) => person1.rank - person2.rank);

  return filterDataByLocale(
    sortedPeopleWithResolvedLocale,
    i18LanguageCode,
    null,
    true,
  );
};
