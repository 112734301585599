import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import MainPageTemplate from '../mainPage';
import { getLocalizedOurStoryPagePeople } from '../../../utils/getLocalizedOurStoryPagePeople';
import { useI18next } from 'gatsby-plugin-react-i18next';

const OurStoryPageTemplate: React.FC<PageProps> = props => {
  const { language } = useI18next();
  const { data, pageContext } = props;

  const { nodeLocale } = pageContext;

  const directorBoardPeople = getLocalizedOurStoryPagePeople(
    data?.DirectorBoard?.nodes,
    language,
  );
  const eltPeople = getLocalizedOurStoryPagePeople(
    data?.ELTPeople?.nodes,
    language,
  );
  const companyData = data?.CompanyData;

  const passedProps = {
    ...props,
  };
  passedProps.customData = {
    directorBoardPeople,
    eltPeople,
    companyData,
  };
  passedProps.data = {
    contentfulPage: data.contentfulPage,
    site: data.site,
    headerV3: data.headerV3,
    footerV3: data.footerV3,
    allContentfulGlobalHeaderFooter: data.allContentfulGlobalHeaderFooter,
  };

  passedProps.pageContext = {
    ...pageContext,
    isGradientBackgroundEnabled: nodeLocale === 'en-US',
  };

  return <MainPageTemplate passedProps={passedProps} />;
};

export const ourStoryPageQuery = graphql`
  query OurStoryPageSlug(
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
  ) {
    ...HeaderFooterSocialQuery
    ...PageQuery
    ...LocalesQuery
    ...SiteMetadataQuery
    DirectorBoard: allContentfulPerson(
      filter: { type: { eq: "BOARD_MEMBER" } }
    ) {
      nodes {
        nodeLocale: node_locale
        name
        title
        rank
        titleRichText {
          raw
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
      }
    }
    ELTPeople: allContentfulPerson(filter: { type: { eq: "ELT" } }) {
      nodes {
        nodeLocale: node_locale
        name
        title
        rank
        titleRichText {
          raw
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
      }
    }
    CompanyData: allContentfulCompany {
      edges {
        node {
          mission {
            raw
          }
          vision {
            raw
          }
          emeaHq {
            raw
          }
          hq {
            raw
          }
          apacHq {
            raw
          }
        }
      }
    }
    sitePage {
      context {
        language
        navSlug
        nodeLocale
        slug
      }
    }
  }
`;

export default OurStoryPageTemplate;
